.wrapper{
    display: flex;
    height: 100vh;
    width: 100vw;
}
.container{
    height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;

    .githubContainer{
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        .img{
            width: 80px;
            height: 80px;
            background-image: url('./images/GitHub-Mark.png');
            background-size: 80px 80px;
        }

    }
}