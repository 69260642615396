.scrollableTextArea{
    min-height: 100px;
    max-height: 250px;
    overflow-y: scroll !important;
    overflow-x: hidden;
}

%containerBase{
    margin-left: 20px;
    text-align: left;
    position: relative;
    padding: 20px;
}

.buttonContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: right;

    input[type='button'], button{
        margin: 10px 10px;

        &:last-of-type{
            margin-right: 0px;
        }
    }
}

.column {
    float: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 1px;
}

.labelTop{
    position: absolute !important;
    top: -32px !important;
    font-size: .8rem !important;
}