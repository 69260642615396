.sidebar{
    position: fixed;
    top: 60px;
    height: calc( 100% - 60px);
    background-color: rgb(246, 246, 246);
    width: 0px;
    transition-duration: 300ms;
    border-left: 1px lightgrey solid;
    overflow-x: hidden;
    
    right: 0px;
    &.open{
        width: 200px;
    }

    .content {
        position: relative;
        width: 100%;
        text-align: center;
        margin-top: 30px;

        .header{
            font-weight: 500;

        }

        .connections{
            display: flex;
            flex-direction: column;
        }
      }
      
}