.card {   
    display: flex;
    flex-direction: row;
    
    .cardStatus{
        width:2px;
        align-self: stretch;
        margin-right: 5px;

        &.success{
            background-color: #58fe76;
        }

        &.failed{
            background-color: red;
        }

        &.queued{
            background-color: rgb(164, 164, 164);
        }
        
        &.building{
            background-color: rgb(0, 221, 255);
        }

        cursor: pointer;
    }
    
    $expanded : block;

    .cardContent {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin: 5px 0px;
        padding: 2px;
        background-color: white;

        border-radius: 2px;
        
        &.notExpanded{
            //$expanded : none;
        }

        // -webkit-box-shadow: 5px 3px 10px -2px rgba(0,0,0,0.48);
        // box-shadow: 5px 3px 10px -2px rgba(0,0,0,0.48);

        .row {
            p {
                margin: 0px;
                padding: 0px;

                &.header {
                    font-size: 17px;
                }

                .label {
                    color: #9e9e9e;
                }
            }

            .fieldWithLabel {
                display: flex;
                flex-direction: column;

                label {
                    color: #9e9e9e;
                    font-weight: 700;
                    display: $expanded;
                }
            }
            display: flex;
            flex-direction: row;

            .last {
                margin-left: auto;

                label {
                    display: $expanded;
                    text-align: right;
                }
            }

            .actionButtonContainer {
                margin-top: auto;
                display: $expanded;
                input[type='button'] {
                    margin-right: 10px;

                    &:last-of-type {
                        margin-right: 0px;
                    }
                }
            }
        }

        input[type='button'] {
            background: none !important;
            border: none;
            padding: 0 !important;
            /*input has OS specific font-family*/
            color: #069;
            text-decoration: none;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
