.bundleList{
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 10px;
    margin-bottom: 10px;

    display:flex;
    flex-direction: column;
    gap:10px;
}