.flexRowContainer {
    display: flex;
    flex-direction: row;

    .last {
        margin-left: auto;
    }

    
}


.compatibilityBadge{
    user-select: none;

    &.error{
        &:hover{
            cursor: pointer;
        }
    }
}