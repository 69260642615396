

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}



#sidebar {
    --kdm-sidebard-child-identation: 15px;
    --kdm-font-size-nav-bar: 32px;

    min-width: 250px;
    max-width: 250px;
    min-height: 100vh;
    background: #141414;
    color: #8aa1a1;
    transition: all 0.3s;
    position: fixed;

    .nav-bar-link {
        font-size: var(--kdm-font-size-nav-bar);
        padding: 0px;
        &:hover {
            color: gray;
        }
        i {
            font-size: var(--kdm-font-size-nav-bar);
        }
    }

    .sidebar-header {
        text-align: center;
        h4{
            font-size: 18px;
        }
        color: white;
    }

    &.hide {
        margin-left: -250px;
    }

    .middle-margin {
        height: 20vh;
    }
    .collapsible {
        border-top: 0px;
        border-right: 0px;
        border-left: 0px;
        box-shadow: none;
        .collapsible-header {
            &:hover {
                color: gray;
            }

            background-color: transparent;
            color: white;
            border-bottom: 0px;
            font-size: var(--kdm-font-size-nav-bar);

            i.material-icons {
                font-size: var(--kdm-font-size-nav-bar);
            }

            span.material-symbols-outlined{
                font-size: var(--kdm-font-size-nav-bar);
            }
        }

        .collapsible-body {
            border-bottom: 1px solid #ddd1;
            a {
                color: white;
                &:hover {
                    background-color: transparent;
                    cursor: pointer;
                    color: gray;
                    &.active {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    ul li {
        &.active {
            background-color: transparent;
        }
        float: none;
    }

    .accordion {
        --bs-accordion-bg: #141414 !important;
        --bs-accordion-btn-color: #a1a1a1 !important;
        --bs-accordion-active-bg: #141414 !important;
        --bs-accordion-active-color: white !important;
        --bs-accordion-btn-focus-box-shadow: 0px;
        --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
        --bs-accordion-btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
        --bs-accordion-body-padding-x: calc(
            var(--bs-accordion-btn-padding-x) +
                var(--kdm-sidebard-child-identation)
        );
        a {
            display: block;
            color: white;
            text-decoration: none;

            &:hover {
                color: #a1a1a1;
            }
        }
    }

    .no-border {
        outline: 0 !important;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    }
}

@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.hide {
        margin-left: 0;
    }
}

.create-button-flat {
    width: 100%;
    color: #039be5;
    font-weight: 500;
}

.content {
    margin-left: 250px;
}

a[data-toggle='collapse'] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #141414;
}
#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}
#sidebar ul li a:hover {
    color: #2a2a2a;
    background: #fff;
}

.two-column-layout {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: 100vh;

    .layout-column-1 {
        width: 280px;
    }
    .layout-column-2 {
        width: auto;
    }

    .content-fill {
        width: calc(100vw - 530px);
    }

    .row {
        margin: 10px 0px;
        &.single-line {
            height: 40px;
        }
    }

    textarea::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        // border-radius: 10px;
        // background-color: #f5f5f5;
    }

    *::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }

    *:focus::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: gray;
    }

    *::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: lightgray;
    }

    .info_header {
        display: inline-block;
        font-weight: 700;
    }

    .selection-list {
        height: auto;
        display: block;
    }
}
