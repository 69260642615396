
.topBar{
    height: 60px;
    border-bottom: 1px rgb(0 0 0 / 15%) solid;
}

.row{
    display: flex;
    flex-direction: row;
    margin-right: 15px;
    margin-left: 15px;
    justify-content: space-between;
    height: 100%;

    .right{
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: end;
    }

    .left{
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: end;
    }
}


.last{
    margin-top: auto;
    margin-bottom: auto;
    button{
        margin-left: 6px;
        padding:10px;
        border: none;
        color:teal;
        outline: none;
        background-color: transparent;
        font-weight: 500;
        cursor: pointer;

        position: relative;

        &.gearIcon{
            &:hover{
                i{
                    transform: rotateZ(45deg);
                }
            }
            
        }

        div{
            i {
                margin-right: 10px;
            }
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
}