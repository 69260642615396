.store-directory-container {
    #store-item-list {
        padding-top: 40px;
        width: 250px;
        background-color: transparent;
        box-shadow: inset 0 0 0 3000px rgba(255, 255, 255, 0.8);
        color: black;
    }

    #store_item_pagination {
        width: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .store_item_pagination a,
    .store_pagination span {
        color: black;
        float: left;
        text-decoration: none;
        margin: 0px 3px 0px 3px;
    }

    .store_pagination a.active {
        color: cyan;
    }

    select.store_item_list {
        width: inherit;
        background-color: inherit;
        box-shadow: inherit;
        color: black;
        border-width: 0;
        padding: 0.4em;
        scrollbar-width: none;
        overflow: hidden;
        height: 690px;

        option:checked {
            background-color: gray;
            color: lightgrey;
        }
    }

    select.store_item_list:focus {
        outline: none !important;
        border: none;
    }

    .product_area {
        width: 870px;
        height: auto;
        float: left;
        margin-left: 20px;
        text-align: left;
        position: relative;
        color: black;
        padding: 20px;
    }

    .row-label {
        display: inline-block;
        font-weight: 700;
        width: 125px;
        height: 25px;
    }

    .item-select {
        width: 100%;
        height: 200px;
        option {
            color: black;
        }
    }

    .lists-row {
        margin-top: 15px;
        display: grid;
        grid-template-columns: 1fr 50px 1fr;
        grid-template-rows: 36px 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }

    .list-1 {
        grid-area: 2 / 1 / 2 / 2;
    }
    .button-container {
        grid-area: 2 / 2 / 2 / 3;
        input[type='button'] {
            color: black;
            font-weight: 600;
            width: 50px;
            height: 100px;
            background-color: teal;

            &.bottom-button {
                border-width: 2px 0px 0px 0px;
                border-color: black;
            }
            &.bottom-top {
                border: 0px;
            }
        }
    }
    .list-2 {
        grid-area: 2 / 3 / 2 / 4;
    }

    .refresh-button {
        float: left;
        width: 250px;
        height: 80px;

        .refresh {
            width: 100%;
            height: 50%;
        }

        .create-container {
            display: grid;
            grid-template-columns: 84% 16%;
            grid-template-rows: 1fr;
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            height: 50%;
            .create-text {
                height: 100%;
            }
            .create-button {
                height: 100%;
                font-weight: bolder;
                font-size: 24px;
            }
        }
    }

    .item-count-container {
        grid-area: 1 / 3 / 2 / 4;
        background-color: white;
        border: 0px;
        margin: 0px 0.5px 0px 1px;
        border-radius: 5px 0px 0px 5px;
        span {
            float: right;
            margin-right: 5px;
            color: gray;
            vertical-align: middle;
            height: 36px;
            line-height: 36px;
        }
    }

    .bottom-button-bar {
        margin-top: 15px;
        float: right;
        input[type='button'] {
            background-color: teal;
            color: white;
            border: 0px;
            border-radius: 3px;
            width: 120px;
            height: 36px;

            &:disabled {
                background-color: rgb(139, 139, 139);
                cursor: not-allowed;
            }
        }
    }

    #search-bar {
        grid-area: 1 / 1 / 2 / 2;
        width: 100%;
        .search {
            width: 100%;
            position: relative;
            display: flex;
        }

        .searchTerm {
            width: 100%;
            padding: 5px;
            height: 36px;
            outline: none;
            color: black;
        }

        .searchTerm:focus {
            color: black;
        }

        .searchButton {
            width: 40px;
            height: 36px;
            border: 1px solid teal;
            background: teal;
            text-align: center;
            color: #fff;
            border-radius: 0 5px 5px 0;
            cursor: pointer;
            font-size: 20px;
        }

        /*Resize the wrap to see the search bar change!*/
        .wrap {
            width: 30%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .tab-list-container.store-item-tabs {
        width: 250px;
        font-size: 0;
        overflow: hidden;
        background-color: white;

        .noselect {
            -webkit-touch-callout: none; /* iOS Safari */
              -webkit-user-select: none; /* Safari */
               -khtml-user-select: none; /* Konqueror HTML */
                 -moz-user-select: none; /* Old versions of Firefox */
                  -ms-user-select: none; /* Internet Explorer/Edge */
                      user-select: none; /* Non-prefixed version, currently
                                            supported by Chrome, Edge, Opera and Firefox */
          }

        input {
            display: none;
            &:checked + label {
                background: rgb(255, 255, 255);
                color : teal
            }
            @for $i from 1 through 2 {
                &#tab#{$i}:checked {
                    ~ .line {
                        left: #{($i - 1) * 50%};
                    }
                    ~ .content-container #c#{$i} {
                        opacity: 1;
                    }
                }
            }
        }
        label {
            display: inline-block;
            font-size: 16px;
            height: 36px;
            line-height: 36px;
            width: 50%;
            text-align: center;
            background: rgb(122,122,122);
            color: rgb(63, 63, 63);
            position: relative;
            transition: 0.25s background ease;
            cursor: pointer;
            &::after {
                content: '';
                height: 2px;
                width: 100%;
                position: absolute;
                display: block;
                background: teal;
                bottom: 0;
                opacity: 0;
                left: 0;
                transition: 0.25s ease;
            }
            &:hover::after {
                opacity: 1;
            }
        }
        .line {
            position: absolute;
            height: 2px;
            background: #1e88e5;
            width: 50%;
            top: 34px;
            left: 0;
            transition: 0.25s ease;
        }
      
    }
}
