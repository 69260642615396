.connectWithGithubContainer{
    
    &.btn{
        color:black;
        height: 40px;
        line-height: 40px;
        width: 100%;
    }

    .buttonContent{
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;
        justify-items: center;
        i{
            margin-right: 10px;
        }
        $imgSize : 25px;
        .img{
            width: $imgSize;
            height: $imgSize;
            background-image: url('./images/GitHub-Mark.png');
            background-size: $imgSize $imgSize;
            margin-right: 10px;
        }

        &.minimize{
            i, .img{
                margin-right: 0px;
            }
        }
    }

    
}