.hearbeatAnimation {
    animation: beat 0.75s infinite alternate;
    transform-origin: center;
}

/* Heart beat animation */
@keyframes beat {
    to {
        transform: scale(1.2);
    }
}
