@use '../../../styles/generalLayout';
@use '../devops-styles.module.scss';

.topBar{
    height: 60px;
    border-bottom: 1px rgb(0 0 0 / 15%) solid;
}

.row{
    display: flex;
    flex-direction: row;
    margin-left: 5px;
}


.last{
    margin-top: auto;
    margin-bottom: auto;
    button{
        margin-left: 6px;
        padding:10px;
        border: none;
        color:teal;
        outline: none;
        background-color: transparent;
        font-weight: 500;
        cursor: pointer;

        position: relative;

        &.gearIcon{
            &:hover{
                i{
                    transform: rotateZ(45deg);
                }
            }
            
        }

        div{
            i {
                margin-right: 10px;
            }
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
}

.dropdown{
    z-index: 1000;
    position: absolute;
    background: white;
    right: 0px;
    width: 100px;
    text-align: right;
    border: 1px solid black;
    .dropdownInner{
        display: flex;
        flex-direction: column;

        button{
            color:teal;

            &:hover{
                color:rgb(0, 187, 187);
            }
        }
    }
}

.dropdownContainer{
    :global{
        .input-field{
            margin-left: 30px;
            margin-top: 6px !important;
            margin-bottom: auto !important;
        }

        .select-dropdown{
            border:none !important;
        }
    }
}