.payloadColumn {
    .buttonContainer {
        position: relative;
    }
}

.button {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
}

.outlineButton {
    border: 0.0625rem solid #228be6;
    border-radius: 4px;
    height: 20px;
    background-color: white;
    color: #228be6;
    font-size: 12px;
    cursor: pointer;

    &:hover {
        background-color: #228be6;
        color: white;
    }
}

.table {
    tr {
        &:last-child {
            background-color: red;
        }
    }
}
