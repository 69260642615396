body {
    .tab-list-container {
        width: 250px;
        font-size: 0;
        overflow: hidden;
        background-color: #000;

        .noselect {
            -webkit-touch-callout: none; /* iOS Safari */
              -webkit-user-select: none; /* Safari */
               -khtml-user-select: none; /* Konqueror HTML */
                 -moz-user-select: none; /* Old versions of Firefox */
                  -ms-user-select: none; /* Internet Explorer/Edge */
                      user-select: none; /* Non-prefixed version, currently
                                            supported by Chrome, Edge, Opera and Firefox */
          }

        input {
            display: none;
            &:checked + label {
                background: rgb(44, 44, 44);
                color : cyan
            }
            @for $i from 1 through 2 {
                &#tab#{$i}:checked {
                    ~ .line {
                        left: #{($i - 1) * 50%};
                    }
                    ~ .content-container #c#{$i} {
                        opacity: 1;
                    }
                }
            }
        }
        label {
            display: inline-block;
            font-size: 16px;
            height: 36px;
            line-height: 36px;
            width: 50%;
            text-align: center;
            background: #000;
            color: #555;
            position: relative;
            transition: 0.25s background ease;
            cursor: pointer;
            &::after {
                content: '';
                height: 2px;
                width: 100%;
                position: absolute;
                display: block;
                background: cyan;
                bottom: 0;
                opacity: 0;
                left: 0;
                transition: 0.25s ease;
            }
            &:hover::after {
                opacity: 1;
            }
        }
        .line {
            position: absolute;
            height: 2px;
            background: #1e88e5;
            width: 50%;
            top: 34px;
            left: 0;
            transition: 0.25s ease;
        }
      
    }
}
