.wrapper_creation {
    background-color: #00000080;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .popup {
        width: 350px;
        padding: 10px;
        margin-left: auto;
        margin-right: auto;
        right: 0;
        left: 0;
        border: 1px solid #ccc;
        border-radius: 10px;
        background: white;
        position: absolute;
        box-shadow: 1px 1px 1px #000;
        z-index: 10001;
        animation: popupReveal 0.3s;

        h4{
            text-align: center;
            margin : 0px;
        }
        button {
            margin: 0px 2px;
        }
    }

    .button_container{
        margin-top: 20px;
        float : right;
    }

    @keyframes popupReveal{
        from {top : -33.33%;}
        to {top:33.33%}
    }

    
}