.table{
    border-collapse:collapse;
    outline: 1px lightgray solid;
    font-size: 0.875rem;
    td{
        padding: 8px;
    }
    th{
        text-align: left;
        padding: 8px;
    }
    thead{
        tr{
            background-color: rgb(237, 237, 237);

            th{
                border:0px;
            }
        }
    }
    tbody{
        tr{
            border-bottom: 1px lightgray solid;

            .detailRow{

            }

            &:hover:not(.detailRow){
                background-color: rgb(237, 237, 237);
            }

            

            @keyframes rotated {
                from {transform: rotate(0deg)}
                to {transform: rotate(90deg);}
              }

              @keyframes straight {
                from {transform: rotate(90deg)}
                to {transform: rotate(0deg);}
              }

            .expandButton{
                background-color: transparent;
                border:none;
                width: 100%;
                height: 100%;

                &.disabled{
                    cursor: not-allowed;
                    svg {
                        stroke: rgb(69, 69, 69);
                    }
                }

                svg{
                    stroke: rgb(66, 66, 66);
                    transition: transform 250ms ease-in-out;
                    // animation-name: straight;
                    // animation-duration: 250ms;
                    // animation-fill-mode: forwards; 

                }

                &:hover{
                    cursor: pointer;
                    svg{
                        stroke: rgb(120, 120, 120)
                    }
                }

                &.expanded{
                    svg{
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }
}