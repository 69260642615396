.image-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 16px;
    column-gap: 8px;
    row-gap: 8px;

    user-select: none;

    &:first-child {
        min-width: 160px;
        min-height: 160px;
    }

    .image-item-wrapper {
        background: white;
        border-radius: 6px;
        min-width: 160px;
        min-height: 160px;

        button {
            align-items: center;
            background: white;
            border: 0.0625rem solid lightgray;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            margin: 0;
            padding: 0;
            position: relative;
            width: 100%;

            .img-wrapper {
                align-items: center;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                -webkit-mask-image: radial-gradient(#fff, #000);
                mask-image: radial-gradient(#fff, #000);
                overflow: hidden;
                transform: translateZ(0);
                width: 100%;
                z-index: 5;

                img {
                    display: block;
                    max-height: 100%;
                    max-width: 100%;
                    position: absolute;
                    z-index: 1;
                }

                &::after {
                    content: '';
                    display: block;
                    padding-bottom: 100%;
                    width: 100%;
                }
            }

            .overlay-btn {
                left: 0.5rem;
                opacity: 0.4;
                position: absolute;
                top: 0.5rem;
                transition: opacity 0.1s cubic-bezier(0, 0, 0.42, 1);
                z-index: 12;

                &.main {
                    left: auto;
                    right: 0.5rem;
                    input[type='button'] {
                        color: red;
                    }
                }

                &.is-main {
                    opacity: 1;
                }

                input[type='button'] {
                    background: transparent;
                    border: none;
                }

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    .upload-button-div {
        min-width: 160px;
        min-height: 160px;
        aspect-ratio: 1;
        background-color: white;
        border: 0.0625rem dashed lightgray;
        border-radius: 6px;
        height: auto;
        padding: 0.5rem;

        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        height: 100%;
        justify-content: center;
        position: relative;

        .btn-content {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            gap: 0.75rem;
            justify-content: center;

            button {
                appearance: none;
                background: white;
                border: none;
                border-radius: 0.25rem;
                color: gray;
                cursor: pointer;
                line-height: 1;
                margin: 0.25rem 0;
                padding: 0.25rem 0.375rem;
                position: relative;
                text-align: center;
                text-decoration: none;

                &::after {
                    border-radius: calc(var(--p-border-radius-1) + 0.0625rem);
                    bottom: -0.0625rem;
                    box-shadow: 0 0 0 -0.0625rem var(--p-focused);
                    content: '';
                    display: block;
                    left: -0.0625rem;
                    pointer-events: none;
                    position: absolute;
                    right: -0.0625rem;
                    top: -0.0625rem;
                    transition: box-shadow 0.1s var(--p-ease);
                    z-index: 1;
                }
            }
        }
    }
}
.image-item {
    grid-row: 1;
    grid-column: auto;
}
.image-wrapper {
    background: white;
    min-height: 120px;
    border: 1px solid gray;
    border-radius: 2px;
}
button.image-upload {
    height: 100%;
}

.shopify_form {
    @supports (-webkit-appearance: none) or (-moz-appearance: none) {
        input[type='checkbox'],
        input[type='radio'] {
            --active: teal;
            --active-inner: #fff;
            --focus: 2px rgba(39, 94, 254, 0.3);
            --border: #bbc1e1;
            --border-hover: teal;
            --background: #fff;
            --disabled: #f6f8ff;
            --disabled-inner: #e1e6f9;
            -webkit-appearance: none;
            -moz-appearance: none;
            height: 18px;
            outline: none;
            display: inline-block;
            vertical-align: top;
            position: relative;
            margin: 0;
            cursor: pointer;
            border: 1px solid var(--bc, var(--border));
            background: var(--b, var(--background));
            transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
            &:after {
                content: '';
                display: block;
                left: 0;
                top: 0;
                position: absolute;
                transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
            }
            &:checked {
                --b: var(--active);
                --bc: var(--active);
                --d-o: 0.3s;
                --d-t: 0.6s;
                --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
            }
            &:disabled {
                --b: var(--disabled);
                cursor: not-allowed;
                opacity: 0.9;
                &:checked {
                    --b: var(--disabled-inner);
                    --bc: var(--border);
                }
                & + label {
                    cursor: not-allowed;
                }
            }
            &:hover {
                &:not(:checked) {
                    &:not(:disabled) {
                        --bc: var(--border-hover);
                    }
                }
            }
            &:focus {
                box-shadow: 0 0 0 var(--focus);
            }
            &:not(.switch) {
                width: 18px;
                &:after {
                    opacity: var(--o, 0);
                }
                &:checked {
                    --o: 1;
                }
            }
            & + label {
                font-size: 14px;
                line-height: 14px;
                display: inline-block;
                vertical-align: top;
                cursor: pointer;
                margin-left: 0px;
            }
        }
        input[type='checkbox'] {
            &:not(.switch) {
                border-radius: 4px;
                &:after {
                    width: 5px;
                    height: 9px;
                    border: 2px solid var(--active-inner);
                    border-top: 0;
                    border-left: 0;
                    left: 5.5px;
                    top: 2.3px;
                    transform: rotate(var(--r, 20deg));
                }
                &:checked {
                    --r: 43deg;
                }
            }
            &.switch {
                width: 38px;
                border-radius: 11px;
                &:after {
                    left: 2px;
                    top: 2px;
                    border-radius: 50%;
                    width: 15px;
                    height: 15px;
                    background: var(--ab, var(--border));
                    transform: translateX(var(--x, 0));
                }
                &:checked {
                    --ab: var(--active-inner);
                    --x: 17px;
                }
                &:disabled {
                    &:not(:checked) {
                        &:after {
                            opacity: 0.6;
                        }
                    }
                }
            }
        }
        input[type='radio'] {
            border-radius: 50%;
            &:after {
                width: 19px;
                height: 19px;
                border-radius: 50%;
                background: var(--active-inner);
                opacity: 0;
                transform: scale(var(--s, 0.7));
            }
            &:checked {
                --s: 0.5;
            }
        }
    }

    .quill {
        transition: 0.2s;
    }

    .quill:focus-within {
        outline: teal solid 1px;
    }

    input[type='text'],
    select {
        border-radius: 4px;
        transition: 0.2s;
        width: 180px;
        outline: teal solid 0px;
        border: 1px solid gray;
        &:focus {
            outline: teal solid 1px;
            border-color: transparent;
        }
    }

    background: white;
    .ql-editor {
        min-height: 200px;
    }
    input {
        color: black;
    }
    .shopify-row {
        margin-top: 5px;
    }

    .delivery_date {
        width: 100px;
    }

    .delivery_divider {
        margin: 0px 10px;
    }

    .image-grid {
        display: grid;
        grid-gap: 16px;
        padding: 16px;
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        grid-auto-flow: column;
        grid-auto-columns: minmax(160px, 1fr);
        overflow-x: auto;
        user-select: none;

        &:first-child {
            grid-column: 1 / span 2;
            grid-row: 1 / span 2;
        }

        .image-item-wrapper {
            background: white;
            border-radius: 6px;

            button {
                align-items: center;
                background: white;
                border: 0.0625rem solid lightgray;
                border-radius: 6px;
                display: flex;
                justify-content: center;
                margin: 0;
                padding: 0;
                position: relative;
                width: 100%;

                .img-wrapper {
                    align-items: center;
                    border-radius: 5px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    -webkit-mask-image: radial-gradient(#fff, #000);
                    mask-image: radial-gradient(#fff, #000);
                    overflow: hidden;
                    transform: translateZ(0);
                    width: 100%;
                    z-index: 5;

                    img {
                        display: block;
                        max-height: 100%;
                        max-width: 100%;
                        position: absolute;
                        z-index: 1;
                    }

                    &::after {
                        content: '';
                        display: block;
                        padding-bottom: 100%;
                        width: 100%;
                    }
                }

                .overlay-btn {
                    left: 0.5rem;
                    opacity: 0.4;
                    position: absolute;
                    top: 0.5rem;
                    transition: opacity 0.1s cubic-bezier(0, 0, 0.42, 1);
                    z-index: 12;

                    &.main {
                        left: auto;
                        right: 0.5rem;
                        input[type='button'] {
                            color: red;
                        }
                    }

                    &.is-main {
                        opacity: 1;
                    }

                    input[type='button'] {
                        background: transparent;
                        border: none;
                    }

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }

        .upload-button-div {
            aspect-ratio: 1;
            background-color: white;
            border: 0.0625rem dashed lightgray;
            border-radius: 6px;
            height: auto;
            padding: 0.5rem;

            align-items: center;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            height: 100%;
            justify-content: center;
            position: relative;

            .btn-content {
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                gap: 0.75rem;
                justify-content: center;

                button {
                    appearance: none;
                    background: white;
                    border: none;
                    border-radius: 0.25rem;
                    color: gray;
                    cursor: pointer;
                    line-height: 1;
                    margin: 0.25rem 0;
                    padding: 0.25rem 0.375rem;
                    position: relative;
                    text-align: center;
                    text-decoration: none;

                    &::after {
                        border-radius: calc(var(--p-border-radius-1) + 0.0625rem);
                        bottom: -0.0625rem;
                        box-shadow: 0 0 0 -0.0625rem var(--p-focused);
                        content: '';
                        display: block;
                        left: -0.0625rem;
                        pointer-events: none;
                        position: absolute;
                        right: -0.0625rem;
                        top: -0.0625rem;
                        transition: box-shadow 0.1s var(--p-ease);
                        z-index: 1;
                    }
                }
            }
        }
    }
    .image-item {
        grid-row: 1;
        grid-column: auto;
    }
    .image-wrapper {
        background: white;
        min-height: 120px;
        border: 1px solid gray;
        border-radius: 2px;
    }
    button.image-upload {
        height: 100%;
    }

    select.sim-id {
        color: black;
        height: 26px;
    }

    .bottom-container {
        float: right;

        input[type='button'] {
            border: none;
            padding: 6px 20px;
            margin: 0px 10px;
            border-radius: 4px;
        }

        .save-button {
            background: teal;
            color: white;
        }

        .delete-button {
            background: red;
            color: white;
        }

        .shopify-button {
            background: green;
            color: white;
        }

        .qr-button {
            background: orange;
            color: white;
        }

        input[type='button'] {
            &:disabled {
                background: gray;
            }
        }
    }

    /**
 * Main wrapper
 */
    .select-search {
        min-width: 700px;
        display: inline-block;
        position: relative;
        font-family: 'Nunito Sans', sans-serif;
        box-sizing: border-box;
        border: gray solid 1px;
        border-radius: 4px;
    }

    .select-search *,
    .select-search *::after,
    .select-search *::before {
        box-sizing: inherit;
    }

    /**
 * Value wrapper
 */
    .select-search__value {
        position: relative;
        z-index: 1;
    }

    .select-search__value::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: calc(50% - 9px);
        right: 19px;
        width: 11px;
        height: 11px;
    }

    /**
 * Input
 */
    .select-search__input {
        display: block;
        height: 36px;
        width: 100%;
        padding: 0 40px 0 16px;
        background: #fff;
        border: 1px solid transparent;
        box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        outline: none;
        font-family: 'Noto Sans', sans-serif;
        font-size: 14px;
        text-align: left;
        text-overflow: ellipsis;
        line-height: 36px;
        -webkit-appearance: none;
    }

    .select-search__input::-webkit-search-decoration,
    .select-search__input::-webkit-search-cancel-button,
    .select-search__input::-webkit-search-results-button,
    .select-search__input::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    .select-search__input:not([readonly]):focus {
        cursor: initial;
    }

    /**
 * Options wrapper
 */
    .select-search__select {
        background: #fff;
        box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
    }

    /**
 * Options
 */
    .select-search__options {
        list-style: none;
        width: 100%;
        padding: 1px;
        margin-top: 0px;
    }

    /**
 * Option row
 */
    .select-search__row:not(:first-child) {
        border-top: 1px solid #eee;
    }

    /**
 * Option
 */
    .select-search__option,
    .select-search__not-found {
        display: block;
        height: 36px;
        width: 100%;
        padding: 0 16px;
        background: #fff;
        border: none;
        outline: none;
        font-family: 'Noto Sans', sans-serif;
        font-size: 14px;
        text-align: left;
        cursor: pointer;
    }

    .select-search--multiple .select-search__option {
        height: 48px;
    }

    .select-search__option.is-selected {
        background: teal;
        color: #fff;
    }

    .select-search__option.is-highlighted,
    .select-search__option:not(.is-selected):hover {
        background: rgba(47, 204, 139, 0.1);
    }

    .select-search__option.is-highlighted.is-selected,
    .select-search__option.is-selected:hover {
        background: teal;
        color: #fff;
    }

    .select-search__option:disabled {
        cursor: not-allowed;
    }

    /**
 * Group
 */
    .select-search__group-header {
        font-size: 10px;
        text-transform: uppercase;
        background: #eee;
        padding: 8px 16px;
    }

    /**
 * States
 */
    .select-search.is-disabled {
        opacity: 0.5;
    }

    .select-search.is-loading .select-search__value::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
        background-size: 11px;
    }

    .select-search:not(.is-disabled) .select-search__input {
        cursor: pointer;
    }

    .select-search.is-disabled .select-search__input:hover {
        cursor: not-allowed;
    }

    /**
 * Modifiers
 */
    .select-search--multiple {
        border-radius: 3px;
        overflow: hidden;
    }

    .select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
        transform: rotate(45deg);
        border-right: 1px solid #000;
        border-bottom: 1px solid #000;
        pointer-events: none;
    }

    .select-search--multiple .select-search__input {
        cursor: initial;
    }

    .select-search--multiple .select-search__input {
        border-radius: 3px 3px 0 0;
    }

    .select-search--multiple:not(.select-search--search) .select-search__input {
        cursor: default;
    }

    .select-search:not(.select-search--multiple) .select-search__input:hover {
        border-color: teal;
    }

    .select-search:not(.select-search--multiple) .select-search__select {
        position: absolute;
        z-index: 2;
        top: 38px;
        right: 0;
        left: 0;
        border-radius: 3px;
        overflow: auto;
        max-height: 360px;
    }

    .select-search--multiple .select-search__select {
        position: relative;
        overflow: auto;
        max-height: 260px;
        border-top: 1px solid #eee;
        border-radius: 0 0 3px 3px;
    }

    .select-search__not-found {
        height: auto;
        padding: 16px;
        text-align: center;
        color: #888;
    }
}

.assetList {
    padding-right: 10px;
    /* width */
    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
