p.controllerName{
    margin: 0px;
}

.badgeContainer{
    display: flex;
    width: 80px;
    align-items: center;
    &.center{
        justify-content: center;
    }
}

.routeAccessContainer{
    margin-left: 10px;
    width: 60px;
}

.label{
    margin-right: 5px;
    font-weight: 600;
    text-transform: uppercase;
}