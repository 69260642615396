.list{
    width: inherit;
    background-color: inherit;
    color: black;
    border-width: 0;
    padding: 0.4em;
    scrollbar-width: none;
    overflow-y: hidden;
    overflow-x: auto;
    height: auto;
    margin-bottom: 0px;
    display: block;
    border-top: 1px;
    border-color: rgb(0 0 0 / 15%);
    border-style: solid;
}

.selectList{
    display: none;
}

.list_container{
    width: 320px;
    background-color: transparent;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 2px;
    height: 100%;

    ul{
        margin:0px;
        border: 0px;
        border-top: 1px;
        border-color: rgb(0 0 0 / 15%);
        border-style: solid;
        overflow-y: auto;
        height: 100%;
    }

    .ulContainer{
        height: calc(100vh - 36px - 28px - 60px);
    }
}
